import { scrollToElem } from '../../../../../Common/Resources/src_front/js/helper-functions/scrollToElem';

export const initScrollSearchBlock = () => {
    // VARS
    const buttons = document.querySelectorAll('[data-button-scroll-anchor]');

    // LISTENERS
    [...buttons].forEach((button) => {
        button.addEventListener(
            'click',
            function () {
                scrollToElem('[data-search-banner]', 0, true);
            },
            false,
        );
    });
};
